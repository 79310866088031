<script setup lang="ts">
import type { ClientApiError, Product } from '@shopware-pwa/types';

import {
  getMainImageUrl,
  getTranslatedProperty,
  getProductRoute,
} from '@shopware-pwa/helpers-next';

const props = defineProps<{ product: Product }>();

const { pushSuccess, pushError } = useNotifications();
const { search } = useProductSearch();
const { t } = useI18n();

const { data: productResponse } = useAsyncData(
  'cmsProduct' + props.product?.id,
  async () => {
    const productResponse = await search(props.product?.id, {
      withCmsAssociations: true,
    });
    return productResponse;
  },
);

// const { product, configurator } = useProduct(
//   // productResponse.value?.product,
//   // productResponse.value?.configurator,
// );
/* 
const product = props.product; */

/* 
const colors = ""; */

/* console.log(colors); */

const colors = computed(
  () =>
    productResponse?.value?.configurator
      .find((option) => option.displayType === 'color')
      ?.options?.map((color) => color.colorHexCode) || [],
);

const productOrPromoRoute = computed(() => {
  if (
    props.product.markAsTopseller &&
    props.product.customFields?.custom_headless_link_different
  ) {
    return props.product.customFields?.custom_headless_alternativ_link;
  } else {
    return getProductRoute(props.product);
  }
});

const { product: productRef } = toRefs(props);
const { addToWishlist, removeFromWishlist, isInWishlist } =
  useProductWishlist(productRef);

const toggleWishlistProduct = async () => {
  if (!isInWishlist.value) {
    try {
      await addToWishlist();
      return pushSuccess(
        t(`product.messages.addedToWishlist`, {
          p: props.product?.translated?.name,
        }),
      );
    } catch (error) {
      const e = error as ClientApiError;
      const reason = e?.messages?.[0]?.detail
        ? `Reason: ${e?.messages?.[0]?.detail}`
        : '';
      return pushError(
        `${props.product?.translated?.name} konnte nicht favorisiert werden.\n${reason}`,
        {
          timeout: 5000,
        },
      );
    }
  }
  removeFromWishlist();
};
</script>

<template>
  <article
    v-if="product && productOrPromoRoute"
    class="the-product-card relative flex flex-col gap-5"
    :class="{ relative: product?.markAsTopseller }"
  >
    <div class="product-cover-wrapper relative">
      <RouterLink
        :to="productOrPromoRoute"
        class="card-img-cover relative overflow-hidden bg-[#f8f8f8]"
        :class="{
          'has-secondary-image':
            !product.markAsTopseller &&
            productResponse?.product.media?.length > 1,
          'aspect-square': !product.markAsTopseller,
        }"
        :aria-label="'link to ' + getTranslatedProperty(product, 'name')"
      >
        <NuxtPicture
          format="webp"
          loading="lazy"
          :class="{
            'main-image aspect-square object-center': !product.markAsTopseller,
          }"
          class="aspect-3/4 w-full object-cover"
          :src="getMainImageUrl(product)"
          :alt="getTranslatedProperty(product, 'name')"
        />
        <!-- Secondary image, do not use like this, it's slow af!! -->
        <NuxtPicture
          v-if="
            !product.markAsTopseller &&
            productResponse?.product.media?.length > 1
          "
          format="webp"
          loading="lazy"
          class="secondary-image absolute top-0 aspect-square object-cover"
          :src="
            productResponse?.product.media[0].media.url ===
            getMainImageUrl(product)
              ? productResponse?.product.media[1].media.url
              : productResponse?.product.media[0].media.url
          "
        />
        <!-- Custom Tag -->
        <NuxtPicture
          v-if="product.translated?.customFields?.custom_headless_tag_label"
          format="webp"
          loading="lazy"
          :src="`/images/tags/${product.translated?.customFields?.custom_headless_tag_label}.svg`"
          :alt="
            product.translated?.customFields
              ?.custom_headless_tag_label as string
          "
          class="w-25 absolute left-4 top-4"
        />
      </RouterLink>
      <button
        aria-label="Add to wishlist"
        type="button"
        class="product-card-wishlist-button absolute right-0 top-0 bg-transparent p-4"
        data-testid="product-box-toggle-wishlist-button"
        @click="toggleWishlistProduct"
      >
        <client-only>
          <div
            v-if="isInWishlist"
            class="i-carbon-favorite-filled c-red-500 h-7 w-7"
            data-testid="product-box-wishlist-icon-in"
            title="Aus Wunschliste entfernen"
          />
          <div
            v-else
            class="i-carbon-favorite h-7 w-7"
            data-testid="product-box-wishlist-icon-not-in"
            title="Zur Wunschliste"
          />
          <template #placeholder>
            <div class="i-carbon-favorite h-7 w-7" />
          </template>
        </client-only>
      </button>
    </div>
    <h2
      v-if="product.markAsTopseller"
      class="absolute bottom-3 left-3 w-2/3 font-bold text-white"
    >
      {{ getTranslatedProperty(product, 'name') }}
    </h2>
    <div v-else class="product-infos flex flex-col gap-4 px-4">
      <div class="flex items-center justify-between">
        <RouterLink
          :to="productOrPromoRoute"
          :aria-label="'link to ' + getTranslatedProperty(product, 'name')"
        >
          <p class="text-2xl font-bold">
            {{ getTranslatedProperty(product, 'name') }}
          </p>
        </RouterLink>
        <ProductPrice is-overview class="!text-base" :product="product" />
      </div>
      <p
        v-if="
          product.translated?.customFields[
            'migration_supernatural-merinocom_product_attr15'
          ]
        "
        class="activities-label text-grey text-sm"
      >
        <!-- {{ dummyTextVariant() }} -->
        {{
          product.translated?.customFields[
            'migration_supernatural-merinocom_product_attr15'
          ]
        }}
      </p>
      <div v-if="colors.length <= 0" class="placeholder-colors" />
      <div v-else class="flex items-center gap-2">
        <template v-for="(colorVariant, idx) in colors">
          <span
            v-if="idx < 4"
            class="h4 w-4 rounded-full"
            :style="{ backgroundColor: colorVariant }"
          />
        </template>
        <span v-if="colors.length > 4" class="text-grey pt-1">
          + {{ colors.length - 4 }}
        </span>
      </div>
    </div>
  </article>
  <article v-else class="the-product-card grid grid-flow-row gap-5">
    Loading...
  </article>
</template>
<style lang="scss">
.product-cover-wrapper {
  .product-card-wishlist-button {
    display: none;
  }

  @media screen and (min-width: 1024px) {
    &:hover {
      .product-card-wishlist-button {
        z-index: 2;
        display: block;
      }
    }
  }
}
.card-img-cover {
  display: flex;

  img {
    width: 100%;
  }

  &.has-secondary-image {
    .main-image {
      opacity: 1;
    }
    .secondary-image {
      opacity: 0;
      display: none;
      width: 100%;

      @media screen and (max-width: 1024px) {
        display: none !important;
      }
    }

    @media screen and (min-width: 1024px) {
      &:hover {
        .main-image {
          opacity: 1;
        }
        .secondary-image {
          z-index: 2;
          opacity: 1;
          display: block;
        }
      }
    }
  }
}
</style>
